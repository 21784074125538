// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-old-js": () => import("./../src/pages/projects-old.js" /* webpackChunkName: "component---src-pages-projects-old-js" */),
  "component---src-pages-projects-drink-js": () => import("./../src/pages/projects/drink.js" /* webpackChunkName: "component---src-pages-projects-drink-js" */),
  "component---src-pages-projects-fished-js": () => import("./../src/pages/projects/fished.js" /* webpackChunkName: "component---src-pages-projects-fished-js" */),
  "component---src-pages-projects-safewill-js": () => import("./../src/pages/projects/safewill.js" /* webpackChunkName: "component---src-pages-projects-safewill-js" */),
  "component---src-pages-projects-tictactoe-js": () => import("./../src/pages/projects/tictactoe.js" /* webpackChunkName: "component---src-pages-projects-tictactoe-js" */),
  "component---src-pages-projects-todolist-js": () => import("./../src/pages/projects/todolist.js" /* webpackChunkName: "component---src-pages-projects-todolist-js" */)
}

